import { DragDropContext, Droppable, type DropResult } from "@hello-pangea/dnd"
import { PlusIcon } from "@heroicons/react/20/solid"
import assertNever from "assert-never"
import React, { useCallback } from "react"

import type { UploadedFile } from "../../../../../../core/application/gateways/storage.gateway"
import type { TrainingSectionContentType } from "../../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import type { CallHighlight } from "../../../../../../core/domain/Library.entity"
import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { reorder } from "../../../../../utils/array"
import { getPlainTextFromHtml } from "../../../../../utils/htmlParsing"
import type { DraftTrainingSection } from "../EditContent.page"
import { AddContentModal } from "./AddContentModal.component"
import { EditSectionSelectContent } from "./EditSectionSelectContent.component"
import { FileUploadModal } from "./FileUploadModal.component"
import { HighlightSelectModal } from "./HighlightSelectModal.component"
import { SectionContentCard } from "./SectionContentCard.component"
import { SectionEditNameForm } from "./SectionEditNameForm.component"
import { TextContentModal } from "./TextContentModal.component"

type CurrentSectionEditFormProps = {
	trainingSection: DraftTrainingSection
	onChange: (section: DraftTrainingSection) => void
}

export function CurrentSectionEditForm({ trainingSection, onChange }: CurrentSectionEditFormProps) {
	const [selectedContentType, setSelectedContentType] = React.useState<TrainingSectionContentType | null>(null)
	const handleSectionNameChange = useCallback(
		(newSectionName: string) => {
			onChange({ ...trainingSection, name: newSectionName })
		},
		[onChange, trainingSection],
	)
	const [isAddContentModalOpen, setIsAddContentModalOpen] = React.useState(false)
	const { t } = useLanguage()

	const handleSectionDescriptionChange = useCallback(
		(newSectionDescription: string) => {
			onChange({ ...trainingSection, description: newSectionDescription })
		},
		[onChange, trainingSection],
	)

	const handleContentModalClose = useCallback(() => {
		setSelectedContentType(null)
	}, [])

	const handleItemCommentChange = useCallback(
		(newComment: string, itemIdx: number) => {
			onChange({
				...trainingSection,
				items: trainingSection.items.map((item, idx) => {
					if (idx === itemIdx) {
						return {
							...item,
							description: newComment,
						}
					}
					return item
				}),
			})
		},
		[onChange, trainingSection],
	)

	const handleHighlightSave = useCallback(
		(highlights: CallHighlight[]) => {
			onChange({
				...trainingSection,
				items: [
					...trainingSection.items.filter((x) => x.type !== "highlight"),
					...highlights.map((highlight) => ({
						type: "highlight" as const,
						highlight,
					})),
				],
			})
			handleContentModalClose()
		},
		[handleContentModalClose, onChange, trainingSection],
	)

	const handleAddContentButtonClick = useCallback(() => {
		setIsAddContentModalOpen(true)
	}, [])

	const handleAddContentModalClose = useCallback(() => {
		setIsAddContentModalOpen(false)
	}, [])

	const handleContentTypeChange = useCallback(
		(contentType: TrainingSectionContentType) => {
			handleAddContentModalClose()
			setSelectedContentType(contentType)
		},
		[handleAddContentModalClose],
	)

	const handleFileUploaded = useCallback(
		(type: "pdf" | "video") => (file: UploadedFile) => {
			handleContentModalClose()
			onChange({
				...trainingSection,
				items: [
					...trainingSection.items,
					{
						type,
						fileName: file.name,
						fileUrl: file.url,
						fileId: file.id,
					},
				],
			})
		},
		[handleContentModalClose, onChange, trainingSection],
	)
	const handleVideoUploaded = handleFileUploaded("video")
	const handlePdfUploaded = handleFileUploaded("pdf")

	const handleTextSave = useCallback(
		(text: string) => {
			handleContentModalClose()
			onChange({
				...trainingSection,
				items: [
					...trainingSection.items,
					{
						type: "text",
						text: text,
					},
				],
			})
		},
		[handleContentModalClose, onChange, trainingSection],
	)

	const handleDeleteContent = useCallback(
		(itemIdx: number) => {
			onChange({
				...trainingSection,
				items: trainingSection.items.filter((_, idx) => idx !== itemIdx),
			})
		},
		[onChange, trainingSection],
	)

	const handleDragEnd = React.useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return
			}

			const newItems = reorder(trainingSection.items, result.source.index, result.destination.index)
			onChange({
				...trainingSection,
				items: newItems,
			})
		},
		[onChange, trainingSection],
	)

	return (
		<div className="flex flex-col gap-6">
			<HighlightSelectModal
				onClose={handleContentModalClose}
				open={selectedContentType === "highlight"}
				onSaveHighlights={handleHighlightSave}
				alreadySelectedHighlights={trainingSection.items
					.filter(
						(item): item is { type: "highlight"; highlight: CallHighlight } => item.type === "highlight",
					)
					.map((item) => item.highlight)}
			/>
			<FileUploadModal
				title={t("Upload a video")}
				mimetype="video/*"
				onFileUploaded={handleVideoUploaded}
				onClose={handleContentModalClose}
				open={selectedContentType === "video"}
			/>
			<FileUploadModal
				title={t("Upload a PDF file")}
				mimetype="application/pdf"
				open={selectedContentType === "pdf"}
				onClose={handleContentModalClose}
				onFileUploaded={handlePdfUploaded}
			/>
			<AddContentModal
				open={isAddContentModalOpen}
				onContentSelect={handleContentTypeChange}
				onClose={handleAddContentModalClose}
			/>
			<TextContentModal
				open={selectedContentType === "text"}
				onClose={handleContentModalClose}
				onTextSubmit={handleTextSave}
			/>
			<SectionEditNameForm
				sectionName={trainingSection.name}
				sectionDescription={trainingSection.description ?? ""}
				onSectionNameChange={handleSectionNameChange}
				onSectionDescriptionChange={handleSectionDescriptionChange}
			/>
			<div>
				<p className="flex items-center gap-2 text-lg font-medium leading-6 text-gray-900 border-t border-gray-200 pt-4">
					{t("Section content")}
					{trainingSection.items.length > 0 && (
						<TrackingButton
							type="button"
							onClick={handleAddContentButtonClick}
							eventName="Add content button clicked"
							className="border border-gray-400 rounded-full p-2 hover:bg-indigo-50 hover:text-indigo-500"
						>
							<PlusIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
						</TrackingButton>
					)}
				</p>
				{trainingSection.items.length === 0 ? (
					<div className="mt-4">
						<EditSectionSelectContent onContentSelect={handleContentTypeChange} />
					</div>
				) : (
					<div className="mt-4">
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable" type="group">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{trainingSection.items.map((item, idx) => {
											if (item.type === "highlight") {
												const thumbnailUrl =
													item.highlight.thumbnailUrl ??
													process.env.PUBLIC_URL + "/rippletide_logo_navy_background.svg"

												return (
													<SectionContentCard
														key={item.highlight.id}
														title={item.highlight.comment}
														comment={item.description ?? ""}
														contentType="highlight"
														onCommentChange={(comment) =>
															handleItemCommentChange(comment, idx)
														}
														onDelete={() => handleDeleteContent(idx)}
														index={idx}
														thumbnail={{ url: thumbnailUrl, alt: item.highlight.comment }}
													/>
												)
											}

											if (item.type === "video" || item.type === "pdf") {
												const fileExtension = item.fileName.split(".").pop()
												return (
													<SectionContentCard
														key={item.fileName}
														title={item.fileName}
														comment={item.description ?? ""}
														contentType={item.type}
														onCommentChange={(comment) =>
															handleItemCommentChange(comment, idx)
														}
														onDelete={() => handleDeleteContent(idx)}
														index={idx}
														thumbnail={fileExtension ? { title: fileExtension } : undefined}
													/>
												)
											}

											if (item.type === "text") {
												return (
													<SectionContentCard
														key={item.text}
														title={getPlainTextFromHtml(item.text)}
														comment={item.description ?? ""}
														contentType="text"
														onCommentChange={(comment) =>
															handleItemCommentChange(comment, idx)
														}
														onDelete={() => handleDeleteContent(idx)}
														index={idx}
														thumbnail={{ title: "txt" }}
													/>
												)
											}

											assertNever(item)
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<div className="flex items-center justify-center">
							<TrackingButton
								type="button"
								onClick={handleAddContentButtonClick}
								eventName="Add content button clicked"
								className="border border-gray-400 rounded-full p-2 hover:bg-indigo-50 hover:text-indigo-500"
							>
								<PlusIcon className="h-5 w-5 text-gray-5600" aria-hidden="true" />
							</TrackingButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
