import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import React, { useEffect } from "react"

import {
	type InviteListEntry,
	type InviteState,
	InviteUserForm,
} from "../../../components/shared/InviteUserForm.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useWorkspaceUsers } from "../../../contexts/workspace-users.context"
import { StepCard } from "../components/StepCard.component"

type IInviteTeamStepProps = {
	onComplete: () => void
}

export function InviteTeamStep({ onComplete }: IInviteTeamStepProps) {
	const { t } = useLanguage()
	const { usersGateway } = useDependencies()
	const [inviteState, setInviteState] = React.useState<InviteState>({ status: "idle" })
	const [inviteList, setInviteList] = React.useState<InviteListEntry[]>([{ email: "", role: "user" }])
	const { workspaceUsers } = useWorkspaceUsers()
	const isPristine = inviteList.every((invite) => invite.email === "")
	const { refetchMe } = useAuthenticatedSession()

	useEffect(() => {
		refetchMe()
	}, [refetchMe])

	const handleSubmit = React.useCallback(async () => {
		if (isPristine) {
			onComplete()
			return
		}

		try {
			setInviteState({
				status: "loading",
			})
			const uniqueInvites = inviteList.filter(
				(invite, index) => inviteList.findIndex((x) => x.email === invite.email) === index,
			)

			await Promise.all(uniqueInvites.map(({ email, role }) => usersGateway.inviteUserToWorkspace(email, role)))

			setInviteState({
				status: "done",
			})
			setInviteList([{ email: "", role: "user" }])
			onComplete()
		} catch (e) {
			if (e instanceof Error) {
				setInviteState({
					status: "error",
					error: e,
				})
			}
			console.error("Error:", e)
		}
	}, [inviteList, isPristine, onComplete, usersGateway])

	const canContinue = !isPristine

	return (
		<StepCard title={t("Invite your team")} canContinue={canContinue} onSkip={onComplete} onContinue={handleSubmit}>
			<p className="flex flex-col gap-2">
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />
					{t("Collaborate on your calls & ongoing deals.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Consolidate your pipeline insights.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Create training programs for your team members.")}
				</span>
			</p>
			<div className="flex flex-col gap-2">
				<InviteUserForm
					currentWorkspaceUsers={workspaceUsers ?? []}
					inviteState={inviteState}
					onInviteStateChange={setInviteState}
					inviteList={inviteList}
					onInviteListChange={setInviteList}
				/>
			</div>
		</StepCard>
	)
}
