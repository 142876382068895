import { useEffect } from "react"

import { Layout } from "../../components/shared/Layout/Layout.component"
import { useLanguage } from "../../contexts/language.context"
import { eventBus, Events } from "../../hooks/eventBus"
import { useCalls } from "../../hooks/useCalls/useCalls"
import { useMyPrograms } from "../Training/Programs/TrainingPrograms.page"
import { CalendarSyncedModal } from "./components/CalendarSyncedModal/CalendarSyncedModal.component"
import { RecentProspectingCallsCard } from "./components/RecentProspectingCallsCard.components"
import { UpcomingDaysProspectingScheduleCard } from "./components/TodaysProspectingScheduleCard/UpcomingDaysProspectingScheduleCard"
import { TrainingReminder } from "./components/TrainingReminder.component"

export function DashboardPage() {
	const { t } = useLanguage()
	const {
		calls: _calls,
		refreshCalls,
		isInitialLoading: areCallsLoading,
	} = useCalls({
		onlyCurrentUser: true,
	})
	const { state: myProgramsState } = useMyPrograms()

	useEffect(() => {
		const refreshHandler = () => refreshCalls(Events.CalendarEventRecordingDidBecomeAvailable)
		eventBus.addEventListener(Events.CalendarEventRecordingDidBecomeAvailable, refreshHandler)
		return () => eventBus.removeEventListener(Events.CalendarEventRecordingDidBecomeAvailable, refreshHandler)
	}, [refreshCalls])

	// Keep only the first 5 calls, should be done in the backend?
	const calls = _calls.slice(0, 5)

	return (
		<div className="bg-gray-100">
			<Layout
				pageName={t("Dashboard")}
				PageTitle={<Layout.PageTitle title={t("Dashboard")} className="md:mx-6 mx-2" />}
			>
				{/* space.16 => height of the navbar */}
				<div className="flex-1 flex-col flex-grow flex min-h-[calc(100vh-theme(space.16))]">
					<div className="flex-1 flex">
						<div className="flex-[2] flex">
							<UpcomingDaysProspectingScheduleCard
								hasRecentCalls={Boolean(calls.length)}
								areCallsLoading={areCallsLoading}
								renderCard={1}
							/>
						</div>
						<div className="flex-1 flex">
							<TrainingReminder myProgramsState={myProgramsState} />
						</div>
					</div>
					<UpcomingDaysProspectingScheduleCard
						hasRecentCalls={Boolean(calls.length)}
						areCallsLoading={areCallsLoading}
						renderCard={2}
					/>
					<RecentProspectingCallsCard calls={calls} />
					<CalendarSyncedModal />
				</div>
			</Layout>
		</div>
	)
}
