import clsx from "clsx"

type InputGroupWrapperProps = {
	children: React.ReactNode
	className?: string
}

function InputGroupWrapper({ children, className }: InputGroupWrapperProps) {
	return <div className={clsx("mt-4", className)}>{children}</div>
}

type InputGroupLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

function InputGroupLabel({ children, ...htmlProps }: InputGroupLabelProps) {
	return (
		<label {...htmlProps} className="block text-sm font-medium leading-6 text-gray-900">
			{children}
		</label>
	)
}

type InputGroupInputProps = React.InputHTMLAttributes<HTMLInputElement>

function InputGroupInput({ className, ...htmlProps }: InputGroupInputProps) {
	return (
		<input
			{...htmlProps}
			className={clsx(
				"mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
				className,
			)}
		/>
	)
}

type InputGroupSelectProps = React.InputHTMLAttributes<HTMLSelectElement>

function InputGroupSelect({ ...htmlProps }: InputGroupSelectProps) {
	return (
		<select
			{...htmlProps}
			className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
		/>
	)
}

type InputGroupSelectOptionProps = React.OptionHTMLAttributes<HTMLOptionElement>

function InputGroupSelectOption({ ...htmlProps }: InputGroupSelectOptionProps) {
	return <option {...htmlProps} />
}

type InputGroupHelperTextProps = {
	children: React.ReactNode
	className?: string
}

function InputGroupHelperText({ children, className }: InputGroupHelperTextProps) {
	return <p className={clsx("mt-2 text-sm text-gray-500", className)}>{children}</p>
}

type InputCheckboxGroupProps = React.InputHTMLAttributes<HTMLInputElement> & {
	mainText: string
	subText: string
}

function InputCheckboxGroup({ className, mainText, subText, id, ...htmlProps }: InputCheckboxGroupProps) {
	return (
		<div className="relative flex items-start">
			<div className="flex h-6 items-center">
				<input
					id={id}
					type="checkbox"
					className={clsx("h-4 w-4 rounded border-gray-300 text-sky focus:ring-sky", className)}
					{...htmlProps}
				/>
			</div>
			<div className="ml-3 text-sm leading-6">
				<label htmlFor={id} className="font-medium text-gray-900">
					{mainText}
				</label>{" "}
				<span className="text-gray-500">{subText}</span>
			</div>
		</div>
	)
}

export const Input = {
	Group: InputGroupWrapper,
	Label: InputGroupLabel,
	Input: InputGroupInput,
	Select: InputGroupSelect,
	SelectOption: InputGroupSelectOption,
	CheckboxGroup: InputCheckboxGroup,
	Helper: InputGroupHelperText,
}
