import type { UploadedFile } from "../../../../../../core/application/gateways/storage.gateway"
import { Modal } from "../../../../../components/design-system/Modal.component"
import { FileUploadForm } from "./FileUploadForm.component"

type FileUploadModalProps = {
	open: boolean
	title: string
	mimetype: string
	onClose: () => void
	onFileUploaded: (file: UploadedFile) => void
}

export function FileUploadModal({ open, title, mimetype, onClose, onFileUploaded }: FileUploadModalProps) {
	return (
		<Modal open={open} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">{title}</p>

			<div className="h-full flex flex-col">
				<FileUploadForm onFileUploaded={onFileUploaded} mimeType={mimetype} />
			</div>
		</Modal>
	)
}
