import { LockClosedIcon } from "@heroicons/react/20/solid"

import type { LibraryFolder } from "../../../../core/domain/Library.entity"
import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { type TFunction, useLanguage } from "../../../contexts/language.context"

type FolderDisplayNameProps = {
	folder: LibraryFolder
}

/**
 * Only translates name if this is a special folder. We never translate user-created folder names.
 */
export function FolderDisplayName({ folder }: FolderDisplayNameProps) {
	const { t } = useLanguage()

	if (folder.properties.isUserRoot) {
		return (
			<span className="flex items-center">
				{t("My library")}
				<LockClosedIcon className="h-4 w-4 ml-2" />
			</span>
		)
	}
	if (folder.properties.isWorkspaceRoot) {
		return t("Workspace library")
	}
	if (folder.properties.tags.includes("workspace-ai-highlights")) {
		return (
			<span className="flex flex-row items-center">
				<span className="text-left flex justify-start">
					{t("Team best moments")}&nbsp;
					<SparklesEmojiWithTooltip />
				</span>
			</span>
		)
	}
	if (folder.properties.tags.includes("user-ai-highlights")) {
		return (
			<span className="flex">
				{t("Your best moments")}&nbsp;
				<SparklesEmojiWithTooltip />
			</span>
		)
	}
	return folder.properties.name
}

export function getFolderDisplayNameString(folder: LibraryFolder, t: TFunction) {
	if (folder.properties.isUserRoot) {
		return t("My library")
	}
	if (folder.properties.isWorkspaceRoot) {
		return t("Workspace library")
	}
	if (folder.properties.tags.includes("workspace-ai-highlights")) {
		return t("Team best moments")
	}
	if (folder.properties.tags.includes("user-ai-highlights")) {
		return t("Your best moments")
	}
	return folder.properties.name
}

function SparklesEmojiWithTooltip() {
	const { t } = useLanguage()
	return (
		<Tooltip content={t("Auto-extracted")} wrapperClassName="inline">
			✨
		</Tooltip>
	)
}
