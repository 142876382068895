import type {
	ClaimUserAccountParams,
	IAuthenticationGateway,
	SignupParams,
} from "../../../application/gateways/authentication.gateway"
import { Subscription } from "../../../domain/Subscription.entity"
import type { Team } from "../../../domain/Team.entity"
import { User } from "../../../domain/User.entity"
import { Workspace } from "../../../domain/Workspace.entity"
import { fetchMyWorkspaceSchema } from "./response-schemas"

export class HttpAuthenticationGateway implements IAuthenticationGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async login(email: string, password: string) {
		const endpointUrl = `${this.baseApiUrl}/auth/login`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify({ email, password }),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (res.status !== 200) {
			throw new InvalidSessionError(res.status)
		}
	}

	public async logout() {
		const endpointUrl = `${this.baseApiUrl}/auth/logout`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
		})

		if (res.status !== 200) {
			throw new InvalidSessionError(res.status)
		}
	}

	public async fetchMe(): Promise<{
		workspace: Workspace
		user: User
		subscription: Subscription | null
		teams: Team[]
	}> {
		const endpointUrl = `${this.baseApiUrl}/auth/refresh`
		const response = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
		})

		if (response.status === 402) {
			throw new PaymentRequiredError()
		}

		if (response.status !== 200) {
			throw new InvalidSessionError(response.status)
		}

		const json = await response.json()

		const {
			workspace: workspaceProperties,
			user: userProperties,
			subscription: subscriptionProperties,
			teams,
		} = fetchMyWorkspaceSchema.parse(json)

		const user = User.fromProperties(userProperties)
		const workspace = Workspace.fromProperties(workspaceProperties)
		const subscription = subscriptionProperties ? Subscription.fromProperties(subscriptionProperties) : null

		return {
			workspace,
			user,
			subscription,
			teams,
		}
	}

	public async claimUserAccount(params: ClaimUserAccountParams) {
		const endpointUrl = `${this.baseApiUrl}/auth/claim-user`
		const response = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (response.status !== 200) {
			throw new InvalidUserClaimError()
		}
	}

	public async signup(signupParams: SignupParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/auth/signup`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(signupParams),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (res.status !== 200) {
			throw new Error("Unable to sign-up")
		}
	}
}

export class InvalidSessionError extends Error {
	constructor(code: number) {
		super(`Invalid session (code ${code})`)
	}
}

export class PaymentRequiredError extends Error {
	constructor() {
		super("Payment required")
	}
}

export class InvalidUserClaimError extends Error {
	constructor() {
		super("Invalid user claim")
	}
}
