import { CheckCircleIcon } from "@heroicons/react/20/solid"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type { CrmIntegration } from "../../../../../core/domain/Workspace.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Card } from "../../../../components/design-system/Card.component"
import { Input } from "../../../../components/design-system/Input"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { HubspotSetupFormComponent } from "../../../../components/shared/Crm/HubspotSetupForm.component"
import { SalesforceSetupFormComponent } from "../../../../components/shared/Crm/SalesforceSetupForm.component"
import { PlanBadge } from "../../../../components/shared/PlanBadge.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { makeWorkspaceBillingPath } from "../../config"
import { CrmProviderPicker } from "./CrmProviderPicker.component"
import { useCrmSettings } from "./useCrmSettings"

function getSuccessLabelByCrmIntegration(crmIntegration: CrmIntegration): TranslationKey {
	if (crmIntegration.provider === "salesforce") {
		return crmIntegration.credentials.type === "oauth"
			? "Connected to Salesforce using OAuth"
			: "Connected to Salesforce using password"
	}

	if (crmIntegration.provider === "hubspot") {
		return "Connected to Hubspot using access token"
	}

	assertNever(crmIntegration)
}

export function WorkspaceCrmSettings() {
	const {
		crmIntegration,
		onSubmit,
		salesforceCredentialTypes,
		onCredentialsChange,
		onSelectedProviderChange,
		onInstanceUrlChange,
		providers,
		formStatus,
		isFormSubmittable,
		savedCrmIntegration,
	} = useCrmSettings()
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isCrmIntegrationEnabled = workspace.isPlanFeatureEnabled("crm-integration")

	const title = (
		<label className="flex items-center justify-between font-medium text-lg text-gray-800 w-full">
			<div className="flex items-center gap-x-2">
				{t("CRM integration")}
				{!isCrmIntegrationEnabled && (
					<PlanBadge
						source="settings: crm sidebar"
						minimumPlanName="Flex"
						makeTooltipMessage={(minimumPlanName) =>
							t("Upgrade to a {{minimumPlanName}} plan now to connect your CRM. Click to learn more.", {
								minimumPlanName,
							})
						}
					/>
				)}
			</div>
			{savedCrmIntegration &&
				(isCrmIntegrationEnabled ? (
					<span className="text-sm text-green-500 flex items-center ml-2">
						{getSuccessLabelByCrmIntegration(savedCrmIntegration)}{" "}
						<CheckCircleIcon className="ml-1 h-4 w-4" />
					</span>
				) : (
					<span className="text-sm text-gray-500 flex items-center ml-2">
						{t("⚠️ Your CRM integration is inactive. To reactive it, upgrade to a Flex plan now.")}
						<TrackingLink
							to={makeWorkspaceBillingPath()}
							target="_blank"
							className="text-center underline md:text-right whitespace-nowrap ml-1"
							eventName="CRM settings: integration inactive banner clicked"
						>
							{t("Learn more")}
						</TrackingLink>
					</span>
				))}
		</label>
	)

	return (
		<Card title={title} className="col-span-2">
			<div className="px-6 py-4">
				{!savedCrmIntegration && (
					<p className="mt-2">
						{t(
							"Unlock the full potential of every customer relationship with our hands-free CRM integration.",
						)}
					</p>
				)}
				<form onSubmit={onSubmit} className="mt-4">
					<Input.Label>{t("Pick your CRM provider")}</Input.Label>

					<CrmProviderPicker
						providers={providers}
						crmIntegration={crmIntegration}
						onSelectedProviderChange={onSelectedProviderChange}
						disabled={!isCrmIntegrationEnabled}
					/>
					{crmIntegration.provider === "salesforce" && (
						<SalesforceSetupFormComponent
							crmIntegration={crmIntegration}
							onInstanceUrlChange={onInstanceUrlChange}
							onCredentialsChange={onCredentialsChange}
							isCrmIntegrationEnabled={isCrmIntegrationEnabled}
							salesforceCredentialTypes={salesforceCredentialTypes}
						/>
					)}
					{crmIntegration.provider === "hubspot" && (
						<HubspotSetupFormComponent
							crmIntegration={crmIntegration}
							onCredentialsChange={onCredentialsChange}
							isCrmIntegrationEnabled={isCrmIntegrationEnabled}
						/>
					)}

					<div className="mt-8 flex justify-end">
						<Tooltip
							content={
								!isCrmIntegrationEnabled
									? t("Upgrade to a Flex plan now to connect your CRM.")
									: undefined
							}
						>
							<TrackingButton
								eventName="CRM setup form submitted"
								eventProperties={{ provider: "salesforce", credentialType: "password" }}
								type="submit"
								className={clsx(
									"flex justify-center rounded-full bg-navy-500 px-5 min-w-[124px] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm",
									"bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out",
									"disabled:hover:bg-none disabled:cursor-not-allowed  disabled:text-gray-500 disabled:bg-gray-100 disabled:ring-gray-200",
								)}
								disabled={!isFormSubmittable || !isCrmIntegrationEnabled}
							>
								<span className="flex gap-2 items-center">
									{formStatus.status === "submitting" && <SpinnerIcon className="h-5 w-5 mx-auto" />}
									{formStatus.status === "submitting"
										? t("Testing CRM connection…")
										: t("Test and save my CRM integration")}
								</span>
							</TrackingButton>
						</Tooltip>
					</div>
					{formStatus.status === "error" && (
						<div className="mt-4">
							<Alert
								title={t("An error occurred")}
								description={formStatus.error.message}
								variant="error"
								className="mt-4"
							/>
						</div>
					)}
					{formStatus.status === "success" && (
						<div className="mt-4">
							<Alert
								title={t("Your CRM integration is set!")}
								description={t(
									"The connection has been successfully established and is working properly.",
								)}
								variant="success"
								className="mt-4"
							/>
						</div>
					)}
				</form>
			</div>
		</Card>
	)
}
