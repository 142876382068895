import { useAnalytics } from "../../contexts/analytics.context"
import { useLanguage } from "../../contexts/language.context"
import { Input } from "../design-system/Input"

type CallSearchInputProps = {
	search: string
	onChange: (search: string) => void
}

export function CallSearchInput({ search, onChange }: CallSearchInputProps) {
	const { setupEventTracking } = useAnalytics()
	const { t } = useLanguage()

	const { trackEvent: trackSearchBarFocus } = setupEventTracking({
		eventName: "Search bar focused",
	})

	return (
		<Input.Group className="w-[384px]">
			<Input.Label htmlFor="search">{t("Search")}</Input.Label>
			<Input.Input
				id="search"
				name="search"
				type="search"
				placeholder={t("Search calls")}
				value={search}
				onChange={(e) => onChange(e.target.value)}
				onFocus={trackSearchBarFocus}
			/>
		</Input.Group>
	)
}
