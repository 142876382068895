import {
	autoUpdate,
	flip,
	FloatingPortal,
	offset,
	shift,
	useDismiss,
	useFloating,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
} from "@floating-ui/react"
import clsx from "clsx"
import React from "react"

type ITooltipProps = React.PropsWithChildren<{
	content: React.ReactNode
	tooltipClassName?: string
	wrapperClassName?: string
	tooltipStyle?: React.CSSProperties
}>

export function Tooltip({ content, children, tooltipClassName, wrapperClassName, tooltipStyle }: ITooltipProps) {
	const [open, setOpen] = React.useState(false)

	// Set up positioning with floating-ui
	const { refs, floatingStyles, context } = useFloating({
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [offset(6), flip(), shift()],
	})

	// Handle interactions like hover
	const hover = useHover(context, { enabled: true })
	const dismiss = useDismiss(context)
	const role = useRole(context, { role: "tooltip" })

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss, role])

	const mergedReferenceRef = useMergeRefs([refs.setReference])
	const mergedFloatingRef = useMergeRefs([refs.setFloating])

	return (
		<div className={clsx("relative flex", wrapperClassName)} ref={mergedReferenceRef} {...getReferenceProps()}>
			{children}
			{content && open && (
				<FloatingPortal>
					<span
						ref={mergedFloatingRef}
						style={{ ...floatingStyles, ...tooltipStyle }}
						{...getFloatingProps({
							className: clsx(
								"z-20 rounded bg-opacity-70 bg-black p-2 text-xs text-white w-auto",
								tooltipClassName,
							),
						})}
					>
						{content}
					</span>
				</FloatingPortal>
			)}
		</div>
	)
}
