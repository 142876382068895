import i18n from "i18next"
import HttpApi from "i18next-http-backend"
import type { ReactNode } from "react"
import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { initReactI18next, useTranslation } from "react-i18next"

import type translations_en from "../../../public/locales/en/translation.json"
import type translations_es from "../../../public/locales/es/translation.json"
import type translations_fr from "../../../public/locales/fr/translation.json"
import type translations_it from "../../../public/locales/it/translation.json"
import { DateProvider } from "../utils/time"
import { isAuthenticationAuthenticatedState, useAuthentication } from "./authentication.context"

type PluralSuffix = "one" | "other"
type RemoveSuffix<Value extends string> = Value extends `${infer Key}_${PluralSuffix}` ? Key : Value

type TranslationKey_En = RemoveSuffix<keyof typeof translations_en>
type TranslationKey_Fr = RemoveSuffix<keyof typeof translations_fr>
type TranslationKey_Es = RemoveSuffix<keyof typeof translations_es>
type TranslationKey_It = RemoveSuffix<keyof typeof translations_it>

// Ensure all translation files have the same keys
type _StaticAssert1 = AssertAssignable<TranslationKey_En, TranslationKey_Fr>
type _StaticAssert2 = AssertAssignable<TranslationKey_En, TranslationKey_Fr>
type _StaticAssert3 = AssertAssignable<TranslationKey_En, TranslationKey_Es>
type _StaticAssert4 = AssertAssignable<TranslationKey_En, TranslationKey_It>

export type TranslationKey = TranslationKey_En

// new languages can be added through a CLI command from the backend
// ts-node src/apps/cli/translate-i18n-entries.ts --langCode it --langName Italian --srcPath ../frontend/public/locales/en/translation.json --destPath ../frontend/public/locales/it/translation.json
// then similar diff as https://github.com/rippletideco/frontend/commit/c3b28e6193faf072b97c2114125cdf0adcf43da5
const supportedLanguages = {
	fr: {
		alt: "Français",
		flagIsoCode: "FR",
	},
	en: {
		alt: "English",
		flagIsoCode: "GB",
	},
	es: {
		alt: "Español",
		flagIsoCode: "ES",
	},
	it: {
		alt: "Italiano",
		flagIsoCode: "IT",
	},
}

const defaultLanguage: keyof typeof supportedLanguages = "en"

i18n.use(HttpApi)
	.use(initReactI18next)
	.init({
		lng: defaultLanguage,
		backend: {
			loadPath: "/locales/{{lng}}/translation.json",
		},
		interpolation: {
			escapeValue: false,
		},
	})

type LanguageContextProps = {
	language: keyof typeof supportedLanguages
	handleAdminLanguageOverride: (lang: string | null) => void
	isLanguageOverridden: boolean
}

export const LanguageContext = createContext<LanguageContextProps>({
	language: defaultLanguage,
	handleAdminLanguageOverride: () => {
		//
	},
	isLanguageOverridden: false,
})

const ADMIN_LANGUAGE_OVERRIDE_KEY = "adminLangageOverride"

function useUILanguage() {
	const { authenticationState } = useAuthentication()

	const [language, _setLanguage] = useState<keyof typeof supportedLanguages>(defaultLanguage)

	const setLanguage = useCallback(
		(lang: string) => {
			if (lang in supportedLanguages) {
				_setLanguage(lang as keyof typeof supportedLanguages)

				if (i18n.language !== lang) {
					i18n.changeLanguage(language)
				}
			}
		},
		[language],
	)

	const setUILanguage = useCallback(() => {
		const dateProvider = new DateProvider()
		const browserLanguageCode = dateProvider.getLanguageCode()

		let language: keyof typeof supportedLanguages = browserLanguageCode ?? defaultLanguage
		if (isAuthenticationAuthenticatedState(authenticationState)) {
			language = authenticationState.workspace.props.language
		}
		const adminLangageOverride = localStorage.getItem(ADMIN_LANGUAGE_OVERRIDE_KEY)
		if (adminLangageOverride && adminLangageOverride in supportedLanguages) {
			language = adminLangageOverride as keyof typeof supportedLanguages
		}
		setLanguage(language)
	}, [authenticationState, setLanguage])

	const handleAdminLanguageOverride = useCallback(
		(lang: string | null) => {
			if (lang === null) {
				localStorage.removeItem(ADMIN_LANGUAGE_OVERRIDE_KEY)
				setUILanguage()
			} else if (lang in supportedLanguages) {
				localStorage.setItem(ADMIN_LANGUAGE_OVERRIDE_KEY, lang)
				setLanguage(lang)
			}
		},
		[setLanguage, setUILanguage],
	)
	useEffect(() => setUILanguage(), [authenticationState, setUILanguage])

	const languageOverride = localStorage.getItem(ADMIN_LANGUAGE_OVERRIDE_KEY)
	const isLanguageOverridden = Boolean(languageOverride && languageOverride in supportedLanguages)

	return { language, handleAdminLanguageOverride, isLanguageOverridden }
}

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { language, handleAdminLanguageOverride, isLanguageOverridden } = useUILanguage()

	return (
		<LanguageContext.Provider value={{ language, handleAdminLanguageOverride, isLanguageOverridden }}>
			{children}
		</LanguageContext.Provider>
	)
}

export function useLanguage() {
	const { t } = useTranslation()

	const context = useContext(LanguageContext)
	if (!context) {
		throw new Error("useLanguage must be used within a LanguageProvider")
	}

	return {
		t: t as TFunction,
		language: context.language,
		handleAdminLanguageOverride: context.handleAdminLanguageOverride,
		supportedLanguages,
		isLanguageOverridden: context.isLanguageOverridden,
	}
}

type NativeTFunction = ReturnType<typeof useTranslation>["t"]
export type TFunction = (key: TranslationKey, options?: Parameters<NativeTFunction>[1]) => ReturnType<NativeTFunction>
