import { CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/20/solid"
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/solid"
import React, { useEffect } from "react"

import { CalendarSyncButtons } from "../../../components/shared/CalendarSyncButtons.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useCalendarContext } from "../../../contexts/calendar.context"
import { useLanguage } from "../../../contexts/language.context"
import { getCalendarPlatformDisplayName } from "../../../utils/calendarName"
import { StepCard } from "../components/StepCard.component"

type CalendarSyncStepProps = {
	onComplete: () => void
}

export function CalendarSyncStep({ onComplete }: CalendarSyncStepProps) {
	const { t } = useLanguage()
	const targetWindowRef = React.useRef<Window | null>(null)
	const { fetchEvents, calendarAndEvents } = useCalendarContext()
	const { refetchMe } = useAuthenticatedSession()

	useEffect(() => {
		refetchMe()
	}, [refetchMe])

	const calendar = calendarAndEvents.data?.calendar

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			fetchEvents("polling")
		}, 2500)

		if (calendarAndEvents.data?.calendar.status === "connected") {
			if (targetWindowRef.current) {
				targetWindowRef.current.close()
			}

			clearInterval(intervalId)
		}

		return () => clearInterval(intervalId)
	}, [calendarAndEvents.data?.calendar.status, fetchEvents])

	const openWindow = (url: string) => {
		const newWindow = window.open(url)
		if (newWindow) {
			targetWindowRef.current = newWindow
			newWindow.onbeforeunload = () => {
				targetWindowRef.current = null
			}
		}
	}

	const handleConnectWorkCalendarMicrosoftOutlook = () => {
		if (calendar?.status !== "not-found") {
			return
		}

		const url = calendar.microsoftOutlookOAuthUrl
		openWindow(url)
	}

	const handleConnectWorkCalendarGoogle = () => {
		if (calendar?.status !== "not-found") {
			return
		}

		const url = calendar.googleCalendarOAuthUrl
		openWindow(url)
	}

	return (
		<StepCard
			title={t("Sync your calendar")}
			canContinue={calendarAndEvents.data?.calendar?.status === "connected"}
			onContinue={onComplete}
			onSkip={onComplete}
		>
			<p className="flex flex-col gap-2">
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />
					{t("Record and transcribe your calls.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Analyse and summarise your conversations.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Extract actionable insights from your meetings.")}
				</span>
			</p>

			<div className="my-6">
				{calendar?.status === "not-found" && (
					<CalendarSyncButtons
						calendar={calendar}
						onConnectWorkCalendarMicrosoftOutlook={handleConnectWorkCalendarMicrosoftOutlook}
						onConnectWorkCalendarGoogle={handleConnectWorkCalendarGoogle}
					/>
				)}

				{(calendar?.status === "connected" || calendar?.status === "connecting") && (
					<div className="my-4 flex items-center justify-center gap-6 max-w-md m-auto">
						<label className="flex items-center text-sm font-medium leading-6 text-gray-900 border border-gray-300 rounded-md p-2">
							{getCalendarPlatformDisplayName(calendar.platform)} {t("connected")}
							{calendar.status === "connected" && (
								<CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" aria-hidden="true" />
							)}
							{calendar.status === "connecting" && (
								<EllipsisHorizontalCircleIcon
									className="ml-2 h-5 w-5 text-yellow-500"
									aria-hidden="true"
								/>
							)}
						</label>
					</div>
				)}
			</div>
		</StepCard>
	)
}
