import React, { createContext, useContext, useRef, useState } from "react"
import type { OnProgressProps } from "react-player/base"
import type ReactPlayer from "react-player/file"
import { useSearchParams } from "react-router-dom"
import { useLocalStorage } from "react-use"

import type { BaseCall } from "../../core/domain/BaseCall.entity"
import type { Call } from "../../core/domain/Call.entity"
import type { CallTimeRange } from "../utils/callSharing"
import { type AnalyticsProps, useAnalytics } from "./analytics.context"

export type SeekPlayerToTrackingComponent = "timeline" | "transcription" | "review" | "suggestions"

type ICallVideoPlayerContextProps = {
	call: BaseCall
	transcription?: Call["props"]["transcription"]
	chapters?: Call["props"]["chapters"]
	seekPlayerTo: (seconds: number, trackingComponent?: SeekPlayerToTrackingComponent) => void
	playerRef: React.MutableRefObject<ReactPlayer | null>
	isPlaying: boolean
	controls: boolean
	volumePercentage: number
	isMuted: boolean
	currentTimeSeconds: number
	totalDurationSeconds: number
	showControls: boolean
	playBackSpeed: number
	handlePlayPause: () => void
	handleRewind: () => void
	handleForward: () => void
	handlePlay: () => void
	handleMouseOver: () => void
	handleMouseLeave: () => void
	handleVolumeChange: React.ChangeEventHandler<HTMLInputElement>
	handlePause: () => void
	handleEnded: () => void
	handlePlayerReady: () => void
	handleTotalDurationSecondsChange: (newDurationSeconds: number) => void
	handleProgress: (progressState: OnProgressProps) => void
	handlePlayBackSpeedChange: (newSpeed: number) => void
	allowedTimeRanges?: CallTimeRange[]
	hasNeverPlayed: boolean
}

const CallVideoPlayerContext = createContext<ICallVideoPlayerContextProps | undefined>(undefined)

export type ICallVideoPlayerProviderProps = React.PropsWithChildren<{
	call: BaseCall
	allowedTimeRanges?: CallTimeRange[]
	/**
	 * Application is allowed to define a custom progress handler
	 * The default one manages allowed time ranges
	 */
	progressHandler?: (
		progressState: OnProgressProps,
		context: Omit<ICallVideoPlayerContextProps, "handleProgress">,
	) => void
}>

type VideoPlayerState = {
	isPlaying: boolean
	controls: boolean
	volumePercentage: number
	isMuted: boolean
	currentTimeSeconds: number
	isLoaded: number
	totalDurationSeconds: number
}

function useVideoPlayerControlsVisibility() {
	const [showControls, setShowControls] = useState(true)
	const [timeoutId, setTimeoutId] = useState<number | null>(null)

	const handleMouseOver = React.useCallback(() => {
		if (timeoutId) {
			window.clearTimeout(timeoutId)
		}

		setShowControls(true)
		setTimeoutId(window.setTimeout(() => setShowControls(false), 3000)) // auto hide controls if no mouse movement for 3 seconds
	}, [timeoutId])

	const handleMouseLeave = React.useCallback(() => {
		setShowControls(false)
		if (timeoutId) {
			window.clearTimeout(timeoutId)
		}
	}, [timeoutId])

	return { showControls, handleMouseOver, handleMouseLeave }
}

export function CallVideoPlayerProvider({
	children,
	call,
	allowedTimeRanges,
	progressHandler,
}: ICallVideoPlayerProviderProps) {
	const playerRef = useRef<ReactPlayer | null>(null)
	const { setupEventTracking } = useAnalytics()
	const trackVideoInteraction = (eventProperties: AnalyticsProps["eventProperties"]) =>
		setupEventTracking({ eventName: "Call video interaction", eventProperties }).trackEvent()
	const { showControls, handleMouseOver, handleMouseLeave } = useVideoPlayerControlsVisibility()
	const { startVideoAtSeconds, removeSearchParams } = useStartVideoAtSeconds()
	const [hasNeverPlayed, setHasNeverPlayed] = useState(true)

	const [{ isPlaying, controls, volumePercentage, isMuted, currentTimeSeconds, totalDurationSeconds }, setState] =
		useState<VideoPlayerState>({
			isPlaying: false,
			controls: false,
			volumePercentage: 0.8,
			isMuted: false,
			currentTimeSeconds: 0,
			isLoaded: 0,
			totalDurationSeconds: call.props.durationSec || 0,
		})

	const [playBackSpeed, setPlayBackSpeed] = useLocalStorage<number>("playBackSpeed", 1, {
		raw: false,
		serializer: (value) => value.toString(),
		deserializer: (value) => parseFloat(value),
	})

	const _seekPlayerTo = (seconds: number) => {
		if (!playerRef.current) {
			console.log("No playerRef, skipping seek")
			return
		}

		if (allowedTimeRanges) {
			/**
			 * If the time is not in the time range, find the closest time range and seek to the start of that time range
			 * If the time is after the last time range, seek to the end of the last time range
			 */
			const lastTimeRangeEndSec = Math.max(...allowedTimeRanges.map((timeRange) => timeRange.endSec))
			const firstTimeRangeStartSec = Math.min(...allowedTimeRanges.map((timeRange) => timeRange.startSec))
			const clampedSeconds = Math.max(firstTimeRangeStartSec, Math.min(lastTimeRangeEndSec, seconds))
			if (!isFinite(clampedSeconds)) {
				// Happens when there are no allowed time ranges
				// Needs to be fixed in a way or another, but for now, we just skip the seek (shortcut)
				console.error("ERROR!!! Seeking player to NaN or Infinity seconds. Skipping seek")
				return
			}
			playerRef.current.seekTo(clampedSeconds)
			return
		}

		playerRef.current.seekTo(seconds, "seconds")
	}

	const handleSeekPlayerTo = (seconds: number, trackingComponent?: SeekPlayerToTrackingComponent) => {
		_seekPlayerTo(seconds)
		handlePlay()
		if (trackingComponent) {
			trackVideoInteraction({
				type: "seek",
				seekToSeconds: seconds,
				component: trackingComponent,
			})
		}
	}

	const handlePlayPause = () => {
		setState((prevState) => ({ ...prevState, isPlaying: !prevState.isPlaying }))
	}

	const handleVolumeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		trackVideoInteraction({
			type: "volume change",
			volumePercentage: parseFloat(e.target.value),
		})
		setState((prevState) => ({ ...prevState, volumePercentage: parseFloat(e.target.value) }))
	}

	const handlePlay = () => {
		if (hasNeverPlayed) {
			setHasNeverPlayed(false)
		}
		setState((prevState) => ({ ...prevState, isPlaying: true }))
	}

	const handlePause = () => {
		setState((prevState) => ({ ...prevState, isPlaying: false }))
	}

	const handleEnded = () => {
		trackVideoInteraction({
			type: "video ended",
		})
	}

	const handlePlayerReady = () => {
		if (startVideoAtSeconds) {
			handleSeekPlayerTo(startVideoAtSeconds)
			removeSearchParams()
		}
	}

	const handleTotalDurationSecondsChange = (newDurationSeconds: number) => {
		setState((prevState) => ({ ...prevState, totalDurationSeconds: newDurationSeconds }))
	}

	const handleRewind = () => {
		const rewindDurationSeconds = 10
		if (!playerRef.current) return

		_seekPlayerTo(playerRef.current.getCurrentTime() - rewindDurationSeconds)
	}

	const handleForward = () => {
		const forwardDurationSeconds = 10
		if (!playerRef.current) return

		_seekPlayerTo(playerRef.current.getCurrentTime() + forwardDurationSeconds)
	}

	const handlePlayBackSpeedChange = (newSpeed: number) => {
		setPlayBackSpeed(newSpeed)
	}

	const contextValue: Omit<ICallVideoPlayerContextProps, "handleProgress"> = {
		seekPlayerTo: handleSeekPlayerTo,
		playerRef,
		isPlaying,
		controls,
		volumePercentage,
		isMuted,
		currentTimeSeconds,
		totalDurationSeconds,
		showControls,
		playBackSpeed: playBackSpeed || 1,
		handlePlayPause,
		handleRewind,
		handleForward,
		handlePlay,
		handleMouseOver,
		handleMouseLeave,
		handleVolumeChange,
		handlePause,
		handleEnded,
		handleTotalDurationSecondsChange,
		handlePlayerReady,
		handlePlayBackSpeedChange,
		transcription: call.props.transcription,
		chapters: call.props.chapters,
		call,
		allowedTimeRanges,
		hasNeverPlayed,
	}

	const defaultProgressHandler = (progressState: OnProgressProps) => {
		if (!allowedTimeRanges?.length) {
			return
		}

		const isNotInTimeRange = allowedTimeRanges.every(
			(timeRange) =>
				progressState.playedSeconds < timeRange.startSec || progressState.playedSeconds > timeRange.endSec,
		)

		if (isNotInTimeRange) {
			// assume that the time ranges are sorted
			const nextTimeRange = allowedTimeRanges.find(
				(timeRange) => progressState.playedSeconds < timeRange.startSec,
			)
			if (nextTimeRange) {
				playerRef.current?.seekTo(nextTimeRange.startSec)
			} else {
				const lastTimeRange = allowedTimeRanges[allowedTimeRanges.length - 1]
				playerRef.current?.seekTo(lastTimeRange.endSec)
				handlePause()
				handleEnded()
			}
		}
	}

	const handleProgress = (progressState: OnProgressProps) => {
		setState((prevState) => ({ ...prevState, ...progressState, currentTimeSeconds: progressState.playedSeconds }))

		const progressHandlerToUse = progressHandler || defaultProgressHandler

		progressHandlerToUse(progressState, contextValue)
	}

	return (
		<CallVideoPlayerContext.Provider value={{ ...contextValue, handleProgress }}>
			{children}
		</CallVideoPlayerContext.Provider>
	)
}

export function useCallVideoPlayer() {
	const context = useContext(CallVideoPlayerContext)
	if (!context) {
		throw new Error("useCallVideoPlayer must be used within a CallVideoPlayerProvider")
	}

	return context
}

function useStartVideoAtSeconds() {
	const [searchParams, setSearchParams] = useSearchParams()
	const startVideoAtSecondsStr = searchParams.get("startVideoAtSeconds")

	const startVideoAtSeconds = startVideoAtSecondsStr ? parseInt(startVideoAtSecondsStr) : 0

	const removeStartVideoAtSeconds = () => setSearchParams({})

	return {
		startVideoAtSeconds: isFinite(startVideoAtSeconds) ? startVideoAtSeconds : undefined,
		removeSearchParams: removeStartVideoAtSeconds,
	}
}
