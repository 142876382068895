import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"

import type { SignupParams } from "../../../core/application/gateways/authentication.gateway"
import { TrackingExternalHref } from "../../components/design-system/TrackingExternalHref.component"
import { TrackingLink } from "../../components/design-system/TrackingLink.component"
import { ClaimAccountOrSignupForm } from "../../components/shared/ClaimAccountOrSignupForm/ClaimAccountOrSignupForm.component"
import { LoginSignupLayout } from "../../components/shared/Layout/LoginSignupLayout.component"
import { useAuthentication } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { useTrackers } from "../../hooks/useTrackers"
import { makePublicWebsiteUrl } from "../../public-website"
import { makeLoginPath, makeWorkspaceOnboardingPath } from "../../router/Router"

export function SignupPage() {
	const { signup, authenticationState } = useAuthentication()
	const { t } = useLanguage()
	const navigate = useNavigate()
	const { trackGoogleTagManager, trackLinkedinSignupConversion } = useTrackers()

	const handleSignupFormSubmit = async (params: SignupParams) => {
		try {
			await signup(params)
			trackLinkedinSignupConversion()
			trackGoogleTagManager("signup")
			console.log("Signup successful", authenticationState)
			navigate(makeWorkspaceOnboardingPath())
		} catch (error) {
			// Possible improvement: proper error management (not just a try/catch but a switch on the expected errors, if any) with translations
			alert("Signup failed") // not supposed to happen
			console.error("Error:", error)
		}
	}

	return (
		<LoginSignupLayout source="signup">
			<h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-navy">
				{t("Create your account")}
			</h2>
			<div className="mt-10 ">
				<ClaimAccountOrSignupForm handleSubmit={handleSignupFormSubmit} isBusy={false} formType="signup" />
				<div className="mt-4 text-sm text-center text-gray-500 md:text-right flex flex-col md:flex-row justify-center">
					<Trans
						i18nKey="By signing up, you agree to our <1>Terms of Service</1>"
						components={{
							1: (
								<TrackingExternalHref
									href={makePublicWebsiteUrl("/terms-of-service")}
									target="_blank"
									className="text-center md:text-right whitespace-nowrap ml-1"
									eventName="Login: see terms of service"
								/>
							),
						}}
					/>
				</div>
				<div className="mt-4 text-sm text-gray-500 text-center md:text-right flex flex-col items-center md:flex-row justify-center">
					<Trans
						i18nKey="Already have an account? <1>Sign in</1>"
						components={{
							1: (
								<TrackingLink
									className="flex items-center justify-center ml-2 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-navy-500 shadow-sm ring-1 ring-inset ring-navy-500 hover:bg-navy-500 hover:ring-navy-500 hover:text-white transition-all"
									eventName="Signup: switch to login form"
									to={makeLoginPath()}
								/>
							),
						}}
					/>
				</div>
			</div>
		</LoginSignupLayout>
	)
}
