import React from "react"
import { useCallback, useEffect, useState } from "react"
import { useDebounce } from "react-use"

import type { Call } from "../../../core/domain/Call.entity"
import type { Team } from "../../../core/domain/Team.entity"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useWorkspaceUsers } from "../../contexts/workspace-users.context"
import { useQueryParamsState } from "../useQueryParamsState"
import { computeFilters } from "."
import type { ActiveFilters } from "./compute-active-filters"

type IUseCallsParams = {
	onlyCurrentUser?: boolean
}

export function useDebouncedSearchFilter(setPageNumber: (pageNumber: React.SetStateAction<number>) => void) {
	const [_searchFilter, _setSearchFilter] = useQueryParamsState<string | null>("search", null)
	const [debouncedFilter, setDebouncedFilter] = useState<string | null>(_searchFilter)

	useDebounce(
		() => {
			setDebouncedFilter(_searchFilter)
			if (_searchFilter) setPageNumber(1)
		},
		250,
		[_searchFilter],
	)

	const handleSearchFilterChange = useCallback((newFilter: string) => _setSearchFilter(newFilter), [_setSearchFilter])

	return {
		activeSearchFilter: debouncedFilter,
		displaySearchFilter: _searchFilter,
		handleSearchFilterChange,
	}
}

export function useCalls({ onlyCurrentUser }: IUseCallsParams = {}) {
	const search = new URLSearchParams(window.location.search)
	const { user, teams } = useAuthenticatedSession()
	const { workspaceUsers } = useWorkspaceUsers()
	const { callsGateway } = useDependencies()

	const [calls, setCalls] = useState<Call[] | null>(null)
	const [totalCalls, setTotalCalls] = useState(0)
	const [areCallsLoading, setCallsLoading] = useState(false)

	const [pageSize] = useState(10)
	const [pageNumber, setPageNumber] = useQueryParamsState("page", 1)
	const { activeSearchFilter, displaySearchFilter, handleSearchFilterChange } =
		useDebouncedSearchFilter(setPageNumber)
	const [selectedTeamId, setSelectedTeamId] = useQueryParamsState<string | null>("teamId", null)
	const [selectedAssignedUserId, setSelectedAssignedUserId] = useQueryParamsState<string | null>(
		"selectedAssignedUserId",
		search.get("selectedAssignedUserId") ?? user.isWorkspaceManager() ? null : user.id,
	)
	const selectedTeam = teams.find((team) => team.id === selectedTeamId) || null

	const filters = React.useMemo(
		() =>
			computeFilters(user, teams, workspaceUsers ?? [], selectedTeamId, selectedAssignedUserId, onlyCurrentUser),
		[onlyCurrentUser, selectedAssignedUserId, selectedTeamId, teams, user, workspaceUsers],
	)

	const listCalls = useCallback(
		async (reason: string, activeFilters: ActiveFilters) => {
			console.log(`Listing calls because: ${reason}`, activeFilters)
			setCallsLoading(reason !== "autorefresh") // don't show loading state when autorefresh
			const response = await callsGateway.list({
				pagination: { pageSize, pageNumber },
				filter: activeFilters,
				search: activeSearchFilter ?? undefined,
			})
			setCalls(response.calls)
			setTotalCalls(response.totalMatchingCalls)
			setCallsLoading(false)
		},
		[callsGateway, pageNumber, pageSize, activeSearchFilter],
	)

	useEffect(() => {
		listCalls("pagination-or-filter-changed", filters.activeFilters)
		if (pageNumber === 1) {
			const interval = setInterval(() => listCalls("autorefresh", filters.activeFilters), 30 * 1000)
			return () => clearInterval(interval)
		}
	}, [filters, listCalls, pageNumber, activeSearchFilter])

	const handlePageNavigation = useCallback(
		(pageStateSetter: React.SetStateAction<number>) => {
			window.scrollTo({ top: 0, behavior: "smooth" })
			setPageNumber(pageStateSetter)
		},
		[setPageNumber],
	)

	const handleSelectedAssignedUserFilterChange = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			const value = e.target.value
			setSelectedAssignedUserId(value)
			setPageNumber(1)
		},
		[setPageNumber, setSelectedAssignedUserId],
	)

	const handleSelectedTeamFilterChange = useCallback(
		(team: Team | null) => {
			// reset selected user
			setSelectedAssignedUserId(null)
			setSelectedTeamId(team ? team.id : null)
			setPageNumber(1)
		},
		[setPageNumber, setSelectedAssignedUserId, setSelectedTeamId],
	)

	const isInitialLoading = calls === null
	const isAnyFilterActive =
		!!activeSearchFilter || selectedTeamId || (!!selectedAssignedUserId && selectedAssignedUserId !== user.id)
	const shouldDisplayDemoCall = !isAnyFilterActive && totalCalls === 0 && !isInitialLoading

	return {
		filters,
		calls: calls ?? [],
		allMembers: workspaceUsers ?? [],
		totalCalls,
		pageNumber,
		pageSize,
		totalPages: Math.ceil(totalCalls / pageSize),
		handlePageNavigation,
		handleSelectedAssignedUserFilterChange,
		handleSelectedTeamFilterChange,
		refreshCalls: (reason: string) => listCalls(reason, filters.activeFilters),
		isInitialLoading,
		areCallsLoading,
		searchFilter: displaySearchFilter,
		selectedTeam,
		selectedTeamId: selectedTeamId ?? "#any#",
		selectedAssignedUserId: selectedAssignedUserId ?? "#any#",
		handleSearchFilterChange,
		shouldDisplayDemoCall,
	}
}
