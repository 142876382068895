import clsx from "clsx"
import { omit } from "lodash"
import React from "react"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type ButtonProps = {
	size?: "sm" | "md" | "lg" | "xs"
	variant?: "default" | "primary" | "danger" | "text"
	icon?: React.ReactNode
} & AnalyticsProps &
	React.ButtonHTMLAttributes<HTMLButtonElement>

export function Button({
	size = "md",
	variant = "default",
	icon,
	children,
	eventName,
	eventProperties,
	onClick,
	disabled,
	...props
}: ButtonProps) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent: trackButtonUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleTrackingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (disabled) return // Prevent tracking if button is disabled
		console.debug("[Button] Tracking event:", eventName, eventProperties)
		trackButtonUsage()
		onClick?.(e)
	}

	const sizeClasses = {
		xs: "px-2 py-1 text-xs",
		sm: "px-2.5 py-1.5 text-sm",
		md: "px-3 py-2 text-sm",
		lg: "px-3.5 py-2.5 text-sm",
		text: "", // No additional padding for text variant
	}

	const variantClasses = {
		default:
			"bg-white text-black hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 ring-1 ring-inset ring-gray-300",
		primary: "bg-navy-700 text-white hover:bg-navy-500 disabled:bg-navy-300 disabled:text-white",
		danger: "bg-red-600 text-white hover:bg-red-500 disabled:bg-red-300 disabled:text-white",
		text: "bg-transparent text-navy-700 hover:text-navy-500 disabled:text-gray-400", // Text variant styles
	}

	return (
		<button
			type="button"
			className={clsx(
				"inline-flex items-center gap-x-1.5 rounded-md font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700 disabled:opacity-50 disabled:cursor-not-allowed",
				sizeClasses[size],
				variantClasses[variant],
				variant === "text" && "hover:underline",
				props.className,
			)}
			onClick={handleTrackingClick}
			disabled={disabled}
			{...omit(props, "className")}
			{...debugDOMProps}
		>
			{icon && <span className="-ml-0.5 h-5 w-5">{icon}</span>}
			{children}
		</button>
	)
}
