import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { Fragment } from "react"
import * as React from "react"

import type { UserProperties } from "../../../../../core/domain/User.entity"
import { UserWorkspaceRoleBadge } from "../../../../components/shared/UserWorkspaceRoleBadge.component"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"

export const roleTranslationKeyByRole: Record<UserProperties["workspaceRole"], TranslationKey> = {
	admin: "Owner",
	manager: "Manager",
	user: "Member",
}

const roleOptions: Array<{
	value: UserProperties["workspaceRole"]
	title: TranslationKey
	description: TranslationKey
}> = [
	{
		value: "admin",
		title: "Owner",
		description: "Access to all calls, deals, and analytics, and full administrative control",
	},
	{ value: "manager", title: "Manager", description: "Access to calls, deals, and analytics of their team members" },
	{
		value: "user",
		title: "Member",
		description: "Access to calls of their team members, but only their own deals and analytics",
	},
]

type IUserWorkspaceRoleDropdownProps = {
	role: UserProperties["workspaceRole"]
	onChange: (role: UserProperties["workspaceRole"]) => void
	readonly?: boolean
}

export function UserWorkspaceRoleDropdown({ role, onChange, readonly }: IUserWorkspaceRoleDropdownProps) {
	const { t } = useLanguage()
	const handleChange = React.useCallback(
		(newRole: string) => {
			onChange?.(newRole as UserProperties["workspaceRole"])
		},
		[onChange],
	)

	if (readonly) {
		return (
			<span className="inline-flex items-center text-xs font-medium text-gray-900 gap-x-1">
				<UserWorkspaceRoleBadge workspaceRole={role} />
				{t(roleTranslationKeyByRole[role])}
			</span>
		)
	}

	return (
		<Listbox value={role} onChange={handleChange}>
			{({ open }) => (
				<>
					<div className="relative">
						<div className="inline-flex divide-x rounded-md shadow-sm border border-gray-300">
							<div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-2 text-gray-900 shadow-sm">
								<UserWorkspaceRoleBadge workspaceRole={role} />

								<p className="text-xs font-semibold">{t(roleTranslationKeyByRole[role])}</p>
							</div>
							<Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50">
								<ChevronDownIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
							</Listbox.Button>
						</div>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="group absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								{roleOptions.map((option) => (
									<Listbox.Option
										key={option.title}
										className={({ active }) =>
											clsx(
												active ? "bg-indigo-600 text-white" : "text-gray-900",
												"cursor-default select-none p-2 text-sm",
											)
										}
										value={option.value}
									>
										{({ selected, active }) => (
											<div className="flex flex-col">
												<div className="flex justify-between">
													<p
														className={clsx(
															selected
																? "font-semibold"
																: "font-normal [&>*]:text-gray-300",
															"flex gap-2",
														)}
													>
														<UserWorkspaceRoleBadge workspaceRole={option.value} />
														{t(option.title)}
													</p>
													{selected && (
														<span className={active ? "text-white" : "text-indigo-600"}>
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													)}
												</div>
												<p
													className={clsx(
														active ? "text-indigo-200" : "text-gray-500",
														"mt-2 text-xs",
													)}
												>
													{t(option.description)}
												</p>
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}
