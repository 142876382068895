import { z } from "zod"

import type { TFunction } from "../../app/contexts/language.context"
import { DateFormatter, DateProvider, type DayAndTime } from "../../app/utils/time"

export const chaptersSchema = z.optional(
	z.array(
		z.object({
			name: z.string(),
			startTimeSec: z.coerce.number(),
			endTimeSec: z.coerce.number(),
		}),
	),
)
export const transcriptionSchema = z.object({
	turns: z.array(
		z.object({
			content: z.string(),
			startTimeSec: z.coerce.number(),
			endTimeSec: z.coerce.number(),
			attendeeId: z.string(),
			attendeeLabel: z.string(),
		}),
	),
})

export const baseCallResponseSchema = z.object({
	id: z.string(),
	workspaceId: z.string(),
	createdAt: z.string(),
	transcription: z.optional(transcriptionSchema),
	chapters: chaptersSchema,
	durationSec: z.number().optional(),
	name: z.string(),
	video: z.optional(
		z.object({
			mimeType: z.string(),
			url: z.string(),
			thumbnail: z.object({ url: z.string(), mimeType: z.string() }).nullish(),
			timelineImage: z.object({ url: z.string(), mimeType: z.string() }).nullish(),
		}),
	),
	language: z.string().nullable().optional(),
	nextBestActionSuggestion: z.optional(
		z.object({
			keyPoints: z.array(z.string()),
			nextBestActions: z.array(z.string()),
			email: z.string(),
			notes: z.array(z.string()),
		}),
	),
})

export const publicCallResponseSchema = baseCallResponseSchema.extend({
	workspaceId: z.string(),
})

type BaseCallApiResponse = z.infer<typeof baseCallResponseSchema>

export type BaseCallProperties = Omit<BaseCallApiResponse, "createdAt"> & {
	createdAt: Date
	videoFileUrl: string | undefined
	countryCode: string | undefined
}

export class BaseCall<CallProperties extends BaseCallProperties = BaseCallProperties> {
	protected readonly _props: CallProperties
	constructor(_props: CallProperties) {
		this._props = _props
	}

	public getCreatedAtDayAndTime(t: TFunction): DayAndTime {
		const dateFormatter = new DateFormatter(new DateProvider(), t)

		return dateFormatter.getFormattedDate(this._props.createdAt)
	}

	public getFormattedDuration(): string | null {
		const durationSec = this._props.durationSec

		if (typeof durationSec !== "number") {
			return null
		}

		const hours = Math.floor(durationSec / 3600)
		const minutes = Math.floor((durationSec % 3600) / 60)

		let formattedDuration = ""

		if (hours > 0) {
			formattedDuration += `${hours}h`
		}
		if (minutes > 0 || hours > 0) {
			formattedDuration += `${minutes}m`
		}

		return formattedDuration
	}

	public get props(): CallProperties {
		return this._props
	}
}
