import { z } from "zod"

import { workspaceDetailsSchema } from "../../domain/Workspace.entity"
import { coachingSessionSchema } from "../../infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"
import { subscriptionStatusSchema } from "./billing.gateway"

export type CreateWorkspaceWithOwnerParams = {
	ownerEmail: string
}

const accountBusinessSummarySchema = z.object({
	// A concise introduction to the company, including its founding year, mission, values, and core offerings. This sets the stage for a deeper understanding of the company.
	overview: z.string().nullish(),

	// Detailed descriptions of the company's main products or services, including any flagship offerings, unique selling points (USPs), and how these offerings solve specific customer problems.
	productsAndServices: z.string().nullish(),

	// Information on who the company's primary customers are, including demographic, geographic, and psychographic details. This might also cover niche markets the company serves.
	targetMarket: z.string().nullish(),

	// Insights into the company's position within its industry, such as market share, rank among competitors, and perceived strengths and weaknesses.
	marketPosition: z.string().nullish(),

	// Explanation of how the company generates revenue, including its pricing strategy, sales channels, and any notable partnerships or customer relationships.
	businessModel: z.string().nullish(),

	// Details on the company's approach to growth, such as expansion plans, new market entry strategies, product development focus, and investment in marketing or technology.
	growthStrategy: z.string().nullish(),

	// Analysis of the major challenges facing the company (e.g., regulatory hurdles, competitive pressure) and opportunities it might be poised to exploit (e.g., market trends, technological advancements).
	challengesAndOpportunities: z.string().nullish(),

	// A brief overview of key financial figures or milestones, such as recent funding rounds, annual revenue, profitability status, and any known financial goals or projections.
	financialHighlights: z.string().nullish(),

	// Insights into the company's leadership team, including brief bios of key executives, and information on company culture and values, which can be critical for understanding decision-making processes and company priorities.
	leadershipAndCulture: z.string().nullish(),

	// Overview of the company's focus on innovation, including any known investments in R&D, patents held, or technology partnerships. This can indicate the company's commitment to staying competitive and its potential for future growth.
	innovationAndTechnology: z.string().nullish(),

	// Information on the company's efforts in sustainability, corporate social responsibility (CSR), and ethical practices, which can be increasingly important to customers and partners.
	sustainabilityAndSocialResponsibility: z.string().nullish(),
})

export type AccountBusinessSummary = z.infer<typeof accountBusinessSummarySchema>

const accountIdentifiersSchema = z.object({
	// The primary URL of the company's website.
	websiteUrl: z.string(),

	// The URL of the company's LinkedIn profile, if available.
	linkedinCompanyUrl: z.string().nullish(),

	// The name the company uses in its marketing and communications, which may differ from its legal name.
	companyUsageName: z.string().nullish(),

	// The official legal name of the company as registered with governmental bodies.
	companyLegalName: z.string().nullish(),

	// The physical address of the company's headquarters or primary location.
	companyAddress: z.string().nullish(),

	// An array of phone numbers associated with the company, for contact purposes.
	phoneNumbers: z.array(z.string()).nullish(),

	// An array of email addresses associated with the company, for contact purposes.
	emails: z.array(z.string()).nullish(),

	// An array of strings summarizing recent news articles or press releases about the company.
	recentNews: z.array(z.string()).nullish(),

	// URLs to other social media profiles of the company, such as Twitter, Facebook, Instagram, etc.
	socialMediaProfiles: z.array(z.string()).nullish(),

	// The specific industry or sector the company operates within.
	industry: z.string().nullish(),

	// The total number of employees working at the company.
	employeeCount: z.number().nullish(),

	// The company's reported annual revenue.
	annualRevenue: z.number().nullish(),

	// Names or titles of key contacts within the company, such as decision-makers or department heads.
	keyContacts: z.array(z.string()).nullish(),

	// A list of technologies the company uses, which could include software, hardware, or platforms.
	technologicalStack: z.array(z.string()).nullish(),

	// A list of companies considered to be direct competitors to the company.
	competitors: z.array(z.string()).nullish(),
})

export type AccountIdentifiers = z.infer<typeof accountIdentifiersSchema>

export const enrichedAccountSchema = z
	.object({
		websiteUrl: z.string(),
		linkedinCompanyProfile: z.object({}).passthrough().nullish(),
	})
	.merge(accountBusinessSummarySchema)
	.merge(accountIdentifiersSchema)

export const enrichedContactSchema = z.object({
	linkedin: z
		.object({
			profile: z.object({}).passthrough(),
		})
		.nullish(),
})

export type EnrichedContact = z.infer<typeof enrichedContactSchema>

export type EnrichedAccount = z.infer<typeof enrichedAccountSchema>

export type IEnrichAccountParams = {
	email: string
	firstName?: string
	lastName?: string
}

export const enrichAccountResponseSchema = z.object({
	account: enrichedAccountSchema.nullish(),
	contact: enrichedContactSchema.nullish(),
})

export type EnrichAccountResponse = z.infer<typeof enrichAccountResponseSchema>

export type IRequestCallReprocessingParams = {
	callId: string
}

export const ListAllWorkspacesRouteResponseCallSchema = z.object({
	id: z.string(),
	createdAt: z.coerce.date().nullable(),
})

export const ListAllWorkspacesRouteResponseUserSchema = z.object({
	id: z.string(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	isUnclaimed: z.boolean(),
	workspaceRole: z.enum(["admin", "user", "manager"]),
	lastLoginAt: z.coerce.date().nullable(),
	calendarId: z.string().nullable(),
	calendarStatus: z.string().nullable(),
	recentCalls: z.array(ListAllWorkspacesRouteResponseCallSchema),
})

export type ListAllWorkspacesRouteResponseUser = z.infer<typeof ListAllWorkspacesRouteResponseUserSchema>

export const ListAllWorkspacesRouteResponseSchema = z.array(
	z.object({
		id: z.string(),
		name: z.string().nullish(),
		emailDomains: z.array(z.string()),
		planId: z.string().nullable(),
		createdAt: z.coerce.date(),
		users: z.array(ListAllWorkspacesRouteResponseUserSchema),
		details: workspaceDetailsSchema.nullish(),
		subscription: z
			.object({
				status: subscriptionStatusSchema,
				endAt: z.coerce.date(),
				startedAt: z.coerce.date(),
				provider: z.string(),
				paymentProviderCustomerId: z.string().nullish(),
				paymentProviderSubscriptionId: z.string().nullish(),
			})
			.nullish(),
	}),
)

export type ListAllWorkspacesRouteResponse = z.infer<typeof ListAllWorkspacesRouteResponseSchema>

export const GetWorkspaceUsersTrainingStatisticsSchema = z.array(
	z.object({
		userId: z.string(),
		coachingSessions: z.array(coachingSessionSchema),
	}),
)
export type WorkspaceUsersTrainingStatistics = z.infer<typeof GetWorkspaceUsersTrainingStatisticsSchema>

export type IPlatformAdminGateway = {
	createWorkspaceWithOwner(params: CreateWorkspaceWithOwnerParams): Promise<void>
	enrichAccount(params: IEnrichAccountParams): Promise<EnrichAccountResponse | null>
	listWorkspaces(): Promise<ListAllWorkspacesRouteResponse>
	requestCallReprocessing(params: IRequestCallReprocessingParams): Promise<void>
	getWorkspaceUsersTrainingStatistics(workspaceId: string): Promise<WorkspaceUsersTrainingStatistics>
}
