import assertNever from "assert-never"
import { toast } from "react-toastify"

import { baseToastConfig } from "../../../../../config"
import type { ICalendarEventsGateway } from "../../../../../core/application/gateways/calendar-events.gateway"
import { type CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { CalendarEventCard } from "./CalendarEventCard.component"

export type EventListProps = {
	events: CalendarEvent[]
}

export function EventList({ events }: EventListProps) {
	const { fetchEvents } = useCalendarContext()

	const { calendarEventsGateway } = useDependencies()

	const handleCalendarEventAction = async (
		calendarEventId: string,
		eventAction: "send-bot-to-meeting-now" | "remove-bot-from-call",
	): Promise<void> => {
		switch (eventAction) {
			case "send-bot-to-meeting-now":
				await calendarEventsGateway.sendBotToMeetingNow(calendarEventId)
				break

			case "remove-bot-from-call":
				await removeBotFromCall(calendarEventsGateway, calendarEventId)
				break

			default:
				assertNever(eventAction)
		}

		await fetchEvents("did-update-bot")
	}

	return (
		<ul role="list" className="divide-y divide-gray-200 border-gray-200">
			{events.map((event) => (
				<CalendarEventCard event={event} handleCalendarEventAction={handleCalendarEventAction} />
			))}
		</ul>
	)
}

async function removeBotFromCall(calendarEventsGateway: ICalendarEventsGateway, calendarEventId: string) {
	try {
		const result = await calendarEventsGateway.removeBotFromCall(calendarEventId)
		if (result.success) {
			toast.success("Removed bot from call", baseToastConfig)
		} else {
			console.error("Failed to remove the bot because:", result)
			switch (result.reason) {
				case "bot-not-found":
					toast.error("Failed to remove the bot", baseToastConfig)
					break

				case "bot-not-started":
					toast.error("No bot in the call", baseToastConfig)
					break

				case "failure":
					toast.error("Failed to remove the bot", baseToastConfig)
					break

				default:
					assertNever(result.reason)
			}
		}
	} catch (e) {
		console.error("Failed to remove the bot because:", e)
		toast.error("Failed to remove the bot", baseToastConfig)
	}
	return null
}
