import { INTESCIA_WORKSPACE_ID } from "../../../../config"
import type { Call } from "../../../../core/domain/Call.entity"
import type { User } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { _CallRow } from "./_CallRow.component"
import { _CallRowSkeleton } from "./_CallRowSkeleton.component"

export type CallsTableProps = {
	refreshCalls?: (reason: string) => Promise<void>
	calls: Call[]
	displayAssignedUser: boolean
	users?: User[]
	hideOverallScore?: boolean
	showLoadingSkeleton?: boolean
}

export function CallsTable({
	calls,
	displayAssignedUser,
	users,
	refreshCalls,
	hideOverallScore,
	showLoadingSkeleton,
}: CallsTableProps) {
	const { t } = useLanguage()
	const { user, workspace } = useAuthenticatedSession()

	const displayScoreColumn = !hideOverallScore && !user.isIntesciaCSM()
	const scoreLabel = workspace?.id === INTESCIA_WORKSPACE_ID ? "overall" : "MEDDIC"

	return (
		<>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-1">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										>
											{t("Name")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											{t("Company")}
										</th>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										/>
										{displayScoreColumn && (
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												{t(scoreLabel === "overall" ? "Overall score" : "MEDDIC score")}
											</th>
										)}

										{displayAssignedUser && (
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												{t("Assigned user")}
											</th>
										)}
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											{t("Date")}
										</th>
										{user.isWorkspaceManager() && <th />}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{showLoadingSkeleton
										? [...Array(5).keys()].map((_, index) => <_CallRowSkeleton key={index} />)
										: calls.map((call) => (
												<_CallRow
													key={call.props.id}
													call={call}
													displayAssignedUser={displayAssignedUser}
													displayScore={displayScoreColumn ? scoreLabel : undefined}
													users={users}
													refreshCalls={refreshCalls}
												/>
										  ))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
